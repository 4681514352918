/* Gallery.css */
.gallery-container {
  display: flex;
  flex-wrap: wrap;
}

.gallery-item {
  width: 25%;
  padding: 10px;
  box-sizing: border-box;
}

.gallery-item img {
  width: 100%;
  cursor: pointer;
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

/* Gallery.css */
.lightbox-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lightbox-content img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
